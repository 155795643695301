import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import { inviteColumns, hoodColumns } from "./config";
export default {
  data: function data() {
    return {
      rightList: [{
        name: "UID",
        value: "111"
      }, {
        name: "用户昵称",
        value: "111"
      }, {
        name: "用户名",
        value: "111"
      }, {
        name: "邀请人",
        value: "111"
      }, {
        name: "邀请人数",
        value: "111"
      }, {
        name: "注册时间",
        value: "111"
      }],
      walletList: [{
        name: "钱包地址",
        value: "111"
      }, {
        name: "钱包余额",
        value: "111"
      }, {
        name: "发行状态",
        value: "111"
      }, {
        name: "HOOD ID",
        value: "111"
      }, {
        name: "持有Hood数量",
        value: "111"
      }, {
        name: "持有总价值",
        value: "111"
      }, {
        name: "交易笔数",
        value: "111"
      }, {
        name: "交易总额",
        value: "10ETH"
      }],
      web2List: [{
        name: "Web2总收益",
        value: "111"
      }, {
        name: "Web2总Pump",
        value: "111"
      }, {
        name: "Free Pump总额",
        value: "111"
      }, {
        name: "历史Pump总额",
        value: "111"
      }],
      currentWeb2List: [{
        name: "本期Web2收益",
        value: "111"
      }, {
        name: "本期Pump比例",
        value: "111"
      }, {
        name: "本期Web2 Pump",
        value: "111"
      }, {
        name: "本期Free Pump",
        value: "111"
      }, {
        name: "本期总Pump",
        value: "111"
      }],
      balanceList: [{
        name: "奖励钱包余额",
        value: "111"
      }, {
        name: "Hood协议总收益",
        value: "111"
      }, {
        name: "邀请总收益",
        value: "111"
      }, {
        name: "团长总收益",
        value: "111"
      }],
      nextWeb2List: [{
        name: "下期Web2收益",
        value: "111"
      }, {
        name: "下期Pump比例",
        value: "111"
      }, {
        name: "下期Web2 Pump",
        value: "111"
      }, {
        name: "下期Free Pump",
        value: "111"
      }, {
        name: "下期总Pump",
        value: "111"
      }],
      total: 0,
      page: 1,
      size: 30,
      loading: false,
      dataSource: [],
      activeKey: 1
    };
  },
  computed: {
    columns: function columns() {
      return this.activeKey === 1 ? inviteColumns : hoodColumns;
    }
  },
  methods: {
    handleButton: function handleButton(Type, item) {
      switch (Type) {
        case "view":
          this.$router.push("/im/feedBack/detail?type=view&id=".concat(item.feedbackuid || "", "&number=").concat(item.feedbackbatchno || ""));
          break;
        case "reply":
          this.$router.push("/im/feedBack/detail?type=reply&id=".concat(item.feedbackuid || "", "&number=").concat(item.feedbackbatchno || ""));
          break;
        default:
          break;
      }
    },
    TabChange: function TabChange(key) {
      this.activeKey = key;
      this.page = 1;
      this.getList();
    },
    getList: function getList() {
      // this.getFromData()
      // this.loading = true
      // IMApi.feedBackList(this.createFormValues).then(res => {
      //   // console.log(res);
      //   this.dataSource = res.list || [];
      //   this.total = res.total
      //   this.waitTotal = res.tobereviewedsum || 0
      //   this.doneTotal = res.passedsum || 0
      // }).catch((err) => {}).finally(() => {
      //   this.loading = false
      // })
    },
    onPageSizeChange: function onPageSizeChange(page, size) {
      this.page = page;
      this.size = size;
      this.getList();
    }
  }
};