import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    attrs: {
      bordered: true,
      title: "基本信息-11"
    }
  }, [_c("div", [_c("a-row", {
    attrs: {
      gutter: 24
    }
  }, [_c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "baseCard"
  }, [_c("div", {
    staticClass: "cardDetail-left"
  }, _vm._l(_vm.rightList, function (item, index) {
    return _c("p", {
      key: index,
      staticClass: "itemList"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0), _c("div", {
    staticClass: "cardDetail-right"
  }, _vm._l(_vm.rightList, function (item, index) {
    return _c("p", {
      key: index,
      staticClass: "itemList"
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 0)])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "baseCard"
  }, [_c("div", {
    staticClass: "cardDetail-left"
  }, _vm._l(_vm.walletList, function (item, index) {
    return _c("p", {
      key: index,
      staticClass: "itemList"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0), _c("div", {
    staticClass: "cardDetail-right"
  }, _vm._l(_vm.walletList, function (item, index) {
    return _c("p", {
      key: index,
      staticClass: "itemList"
    }, [item.name !== "钱包地址" ? _c("span", {
      staticClass: "itemList"
    }, [_vm._v(_vm._s(item.value))]) : _c("a", {
      staticClass: "itemList"
    }, [_vm._v(_vm._s(item.value))])]);
  }), 0)]), _c("div", {
    staticClass: "baseCard mt"
  }, [_c("div", {
    staticClass: "cardDetail-left"
  }, _vm._l(_vm.balanceList, function (item, index) {
    return _c("p", {
      key: index,
      staticClass: "itemList"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0), _c("div", {
    staticClass: "cardDetail-right"
  }, _vm._l(_vm.balanceList, function (item, index) {
    return _c("p", {
      key: index,
      staticClass: "itemList"
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 0)])]), _c("a-col", {
    attrs: {
      span: 8
    }
  }, [_c("div", {
    staticClass: "baseCard"
  }, [_c("div", {
    staticClass: "cardDetail-left"
  }, _vm._l(_vm.web2List, function (item, index) {
    return _c("p", {
      key: index,
      staticClass: "itemList"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0), _c("div", {
    staticClass: "cardDetail-right"
  }, _vm._l(_vm.web2List, function (item, index) {
    return _c("p", {
      key: index,
      staticClass: "itemList"
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 0)]), _c("div", {
    staticClass: "baseCard mt"
  }, [_c("div", {
    staticClass: "cardDetail-left"
  }, _vm._l(_vm.currentWeb2List, function (item, index) {
    return _c("p", {
      key: index,
      staticClass: "itemList"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0), _c("div", {
    staticClass: "cardDetail-right"
  }, _vm._l(_vm.currentWeb2List, function (item, index) {
    return _c("p", {
      key: index,
      staticClass: "itemList"
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 0)]), _c("div", {
    staticClass: "baseCard mt"
  }, [_c("div", {
    staticClass: "cardDetail-left"
  }, _vm._l(_vm.nextWeb2List, function (item, index) {
    return _c("p", {
      key: index,
      staticClass: "itemList"
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0), _c("div", {
    staticClass: "cardDetail-right"
  }, _vm._l(_vm.nextWeb2List, function (item, index) {
    return _c("p", {
      key: index,
      staticClass: "itemList"
    }, [_vm._v(" " + _vm._s(item.value) + " ")]);
  }), 0)])])], 1)], 1)]), _c("a-card", {
    staticStyle: {
      "margin-top": "24px"
    },
    attrs: {
      bordered: true
    }
  }, [_c("a-tabs", {
    attrs: {
      "default-active-key": "1"
    },
    on: {
      change: _vm.TabChange
    },
    model: {
      value: _vm.activeKey,
      callback: function callback($$v) {
        _vm.activeKey = $$v;
      },
      expression: "activeKey"
    }
  }, [_c("a-tab-pane", {
    key: 1,
    attrs: {
      tab: "邀请列表"
    }
  }), _c("a-tab-pane", {
    key: 2,
    attrs: {
      tab: "Hood 持有列表"
    }
  })], 1), _c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns,
      rowKey: function rowKey(it, i) {
        return it.id;
      },
      loading: _vm.loading,
      scroll: {
        x: "100%"
      }
    },
    scopedSlots: _vm._u([{
      key: "feedbacknickname",
      fn: function fn(feedbacknickname, row) {
        return [_c("a", {
          attrs: {
            href: "/user/detail?uid=".concat(row.feedbackuid),
            target: "_blank"
          }
        }, [_vm._v(_vm._s(feedbacknickname))])];
      }
    }, {
      key: "feedbacktime",
      fn: function fn(feedbacktime, row) {
        return [_c("span", [_vm._v(_vm._s(_vm.$moment(Number(feedbacktime * 1000)).utcOffset(0).format("YYYY-MM-DD HH:mm:ss")))])];
      }
    }, {
      key: "feedbackstatus",
      fn: function fn(feedbackstatus, row) {
        return [_c("span", [_vm._v(_vm._s(feedbackstatus === 0 ? "未处理" : "已处理"))])];
      }
    }, {
      key: "actions",
      fn: function fn(actions, row) {
        return [_c("div", [_c("a", {
          staticStyle: {
            color: "rgb(102, 102, 102)"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("view", row);
            }
          }
        }, [_vm._v("查看")]), _c("a", {
          staticStyle: {
            "margin-left": "20px"
          },
          on: {
            click: function click($event) {
              $event.stopPropagation();
              return _vm.handleButton("reply", row);
            }
          }
        }, [_vm._v("回复")])])];
      }
    }])
  }), _c("pagination", {
    attrs: {
      total: _vm.total,
      page: _vm.page,
      size: _vm.size,
      onPageSizeChange: _vm.onPageSizeChange,
      onShowSizeChange: _vm.onPageSizeChange
    },
    on: {
      "update:page": function updatePage($event) {
        _vm.page = $event;
      },
      "update:size": function updateSize($event) {
        _vm.size = $event;
      }
    }
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };